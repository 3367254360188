import { Breadcrumb, Layout, Menu, theme, Row, Col } from "antd";
import { useState, useEffect } from "react";
import { Outlet, RouterProvider, useLocation, useNavigate } from "react-router-dom";
import logoHeaderSVG from "../../assets/images/logo-header.svg";
import logoFooterSVG from "../../assets/images/logo-footer.svg";
import { HashLink } from "react-router-hash-link";
import "./Main.css";
import { Link, animateScroll as scroll } from "react-scroll";
import { Link as LinkRouter } from "react-router-dom";
import Lottie from "lottie-react";
import loadingAnimation from "../../animations/loading_lottie.json";
import loadingLogoAnimation from "../../animations/data.json";
import DrawerMenu from "../DrawerMenu/DrawerMenu";
import { MenuOutlined } from "@ant-design/icons";

const { Header, Content, Footer } = Layout;

const items = [
  {
    name: "PRÉMIOS",
    key: "premios",
    to: "premios",
    scrollTo: true,
  },
  {
    name: "PRAZOS",
    key: "prazos",
    to: "prazos",
    scrollTo: true,
  },
  {
    name: "COMITÉ CIENTÍFICO",
    key: "committee",
    to: "prazos",
    scrollTo: true,
  },
  {
    name: "REGULAMENTO",
    key: "regulation",
    to: "regulation",
    scrollTo: true,
  },
  /*{
    name: "PROGRAMA DO CURSO",
    key: "programa",
    to: "programa",
    scrollTo: false,
  },*/
];

const Main = () => {
  const [current, setCurrent] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    if (isLoading) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        setIsLoading(false);
        document.body.style.overflow = "auto";
      }, 2000);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  window.onbeforeunload = function () {
    setIsLoading(true);
    window.scrollTo(0, 0);
  };

  function handleCloseMenuMobile() {
    console.log("closed");
    setIsOpenMenuMobile(false);
  }

  return (
    <Layout>
      <div className={`loading_page ${!isLoading ? "hidden" : ""}`}>
        <Lottie animationData={loadingLogoAnimation} />
      </div>
      <Header className="principal_header">
        <div className="content_header">
          {location.pathname === "/inscricao" ||
          location.pathname === "/regulamento" ||
          location.pathname === "/politica-de-privacidade" ||
          location.pathname === "/politica-de-cookies" ? (
            <div className="logo" onClick={() => navigate("/")}></div>
          ) : (
            <Link className="logo" activeClass="active" to={"home"} spy={true} smooth={true} offset={-70} duration={500}></Link>
          )}
          <Menu className="menu_desktop" theme="dark" mode="horizontal" defaultSelectedKeys={[""]} selectedKeys={[current]}>
            {location.pathname === "/inscricao" ||
            location.pathname === "/regulamento" ||
            location.pathname === "/politica-de-privacidade" ||
            location.pathname === "/politica-de-cookies" ? (
              <Menu.Item key={""}>
                <LinkRouter href="/">« Voltar</LinkRouter>
              </Menu.Item>
            ) : (
              <>
                {items.map((item) => {
                  return (
                    <Menu.Item key={item.key}>
                      {item.scrollTo ? (
                        <Link activeClass="active" to={item.to} spy={true} smooth={true} offset={-70} duration={500}>
                          {item.name}
                        </Link>
                      ) : (
                        <LinkRouter to={`/${item.to}`}>{item.name}</LinkRouter>
                      )}
                    </Menu.Item>
                  );
                })}
                {/* <Menu.Item key={"submeter"} className="inscricao_menu_button">
                  <LinkRouter to={"/inscricao"}>SUBMETER</LinkRouter>
                </Menu.Item> */}
              </>
            )}
          </Menu>

          {location.pathname === "/regulamento" || location.pathname === "/inscricao" || location.pathname === "/programa" ? (
            <div className="menu_mobile">
              <LinkRouter className="f-16 white bold" to={`/`}>
                « Voltar
              </LinkRouter>
            </div>
          ) : (
            <div className="menu_mobile" onClick={() => setIsOpenMenuMobile(true)}>
              <MenuOutlined />
            </div>
          )}
          <DrawerMenu open={isOpenMenuMobile} close={handleCloseMenuMobile} />
        </div>
      </Header>
      <Content className="site-layout">
        <Outlet />
      </Content>
      <Footer className="principal_footer">
        <div className="first_row">
          <div className="first_row_content">
            <img src={logoFooterSVG} />
          </div>
        </div>
        <div className="second_row">
          <div className="second_row_content">
            <div>
              <p>
                <LinkRouter to="/politica-de-privacidade">Política Geral de Privacidade</LinkRouter>
                <span>|</span>
                <LinkRouter to="/politica-de-cookies">Política de Cookies</LinkRouter>
              </p>
            </div>
            <div>
              <p>Concurso de Casos Clínicos de Neurologia © 2024</p>
            </div>
          </div>
        </div>
      </Footer>
    </Layout>
  );
};
export default Main;
